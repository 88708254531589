import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M15.297 7.211a1 1 0 0 1 1.406-1.423L18 7.069V1a1 1 0 1 1 2 0v6.052l1.303-1.268a.999.999 0 1 1 1.394 1.433l-2.236 2.177a2.103 2.103 0 0 1-2.964-.009l-2.2-2.173ZM24 11v10c0 1.654-1.346 3-3 3H5c-2.757 0-5-2.243-5-5V9c-.011-2.715 2.222-5.005 5-5h7a1 1 0 1 1 0 2H5c-.887 0-1.686.387-2.235 1.001A3.012 3.012 0 0 0 5 8h8.416c.131.226.281.442.475.634l2.192 2.166c1.504 1.578 4.271 1.589 5.772.027l1.592-1.55c.345.489.552 1.081.552 1.723Zm-4 5a1.5 1.5 0 1 0-3.001.001A1.5 1.5 0 0 0 20 16Z" />
      </g>
    </Svg>
  );
};

export default Icon;
